import React, {  } from "react";
import './product_sstyle.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'aos/dist/aos.css';
import banner from '../img/logo/banner-bg.png';
import amogh from '../img/logo/amogh.png'
import top from '../img/logo/left_top.png';
import bottom from '../img/logo/left_bottom.png';
import right_top from '../img/logo/right_top.png';
import right_bottom from '../img/logo/right_bottom.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FiMail, FiPhone } from "react-icons/fi";
import 'react-medium-image-zoom/dist/styles.css';

// Teams

import top_corner from '../img/products/top_corner.png';
import bottom_corner from '../img/products/bottom_corner.png';
import speaklear from '../img/products/speaklear.png';


function Products() {
  return (
<>
{/* <!-- ======= Start Home Section ======= --> */}

<section id="Home" className="product-home-bg aos-animate" data-aos="fade-up"> 
<img src={top_corner} alt="banner" className="top_corner" width={"200"} /> 
<div className="gap_product_speaklear"><div>
<img src={speaklear} alt="banner" className="logo_icon_speaklear" width={"200"} />
<p className="logo-para-speaklear text-center pt-1">“When you design for a minority, solutions can apply to a majority”</p>
<img src={bottom_corner} alt="banner" className="bottom_corner" width={"200"} />
</div>
  </div>
</section>

    {/* <!-- ======= End Home Section ======= --> */}

{/* <!-- ======= Start Footer Section ======= --> */}
<section className="home-bg">
  <p className="footer">ABLEPRO SOLUTIONS PVT. LTD. | ALL RIGHTS RESERVED | {new Date().getFullYear()}</p>
</section>
{/* <!-- ======= End Footer Section ======= --> */}

  </>
  )
}

export default Products
