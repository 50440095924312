import React, {  } from "react";
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'aos/dist/aos.css';
import banner from '../img/logo/banner-bg.png';
import amogh from '../img/logo/amogh.png'
import top from '../img/logo/left_top.png';
import bottom from '../img/logo/left_bottom.png';
import right_top from '../img/logo/right_top.png';
import right_bottom from '../img/logo/right_bottom.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FiMail, FiPhone } from "react-icons/fi";
import 'react-medium-image-zoom/dist/styles.css';

// Teams

import Karan from '../img/teams/Karan.png';
import Ranganath from '../img/teams/ranganath.png';
import Vishwanath from '../img/teams/Vishwanath.png';
import Vishnukant from '../img/teams/Vishnukant.png';
import Guddushastri from '../img/teams/Guddushastri.png';
import Shailaja from '../img/teams/Shailaja.png';
import Suma from '../img/teams/Suma.png';
import Deepashree from '../img/teams/deepashree.png';
import Indumathi from '../img/teams/Indumathi.png';




function Home() {
 
  
  return (

    
<>
{/* <!-- ======= Start Home Section ======= --> */}

<section id="Home" className="home-bg aos-animate" data-aos="fade-up">
  
  <div className="gap">

  <div>
<img src={banner} alt="banner" className="logo_icon" width={"200"} />
<h1 className="logo-heading text-center pt-2">AblePro Solutions</h1>
<p className="logo-para text-center pt-1">Enable, Engage, Empower</p>
</div>
  </div>
</section>

    {/* <!-- ======= End Home Section ======= --> */}

    {/* <!-- ======= Start About Section ======= --> */}

    <section id="About" className="about-bg">
    <img src={right_bottom} alt="banner" className="rightdesign" width={"20%"} />
    <img src={top} alt="banner" className="topdesign" width={"20%"} />
      <Container className="aos-animate" data-aos="fade-up">
        <div className="gap">
        <Row>
        <div className=" aos-animate" data-aos="fade-up" >
      <h1 className="about-heading">Namaste!</h1>
      <p className="about-para pt-3">We are <b>AblePro Solutions Pvt. Ltd.</b>, a startup company registered on September 2024,
with objectives to design, develop, provide, host mobile and web applications and carry on the
business of providing assistive technology services and technology platforms for the disability
sector and conducting research in overcoming everyday challenges of people with disabilities
and set up infrastructures for assistive technology products.</p><br/>
<p className="about-para-last">Our core beliefs with respect to our work —<span>  ‘Enable, Engage, Empower.’</span>  </p>
    </div>
    </Row>
    </div>
      </Container>
    </section>
{/* <!-- ======= End About Section ======= --> */}

{/* <!-- ======= Start Amogh_Inamdar Section ======= --> */}
<section className="home-bg">
<img src={bottom} alt="banner" className="bottomdesign" width={"20%"} />
<img src={right_top} alt="banner" className="righttopdesign" width={"20%"} />
  <Container>
  <div className="gap"> 
   <Row > 
      
    <Col lg={4} md={12} sm={12} xs={12} className="aos-animate" data-aos="fade-up">
    <div className="amogh-img">
    <img src={amogh} alt="amogh" width={"100%"} />
    <h2 className="amog-heading pt-2">Amogh Inamdar</h2>
    <h4 className="amog-sub-heading">M.Des, IDC, IIT Bombay</h4>
    <p className="amog-para">Founder, Director</p>
    </div>
      </Col>

      <Col lg={8} md={12} sm={12} xs={12} className="aos-animate" data-aos="fade-up">
          <p className="amog-parag pt-1">‘‘As a person with a disability, I have faced and overcome numerous challenges throughout my life. This journey has fuelled my desire to build a comprehensive technology platform that serves the needs of people with disabilities across and strengthening our community. I am committed to listening to the current challenges and finding innovative
          solutions to address them.<br/><br/>
          I never set out to create a company, but I have always dreamed big. One of the most 
impactful statements I carry with me is from a professor at IIT Bombay:
‘‘It is easy to make money, but the same cannot be said of making meaning in your life.’’<br/><br/>
Through this endeavour, I aspire to bring meaningful change and transformation
to the community I care deeply about.’’</p>
       
        </Col>
      </Row>
      </div>
  </Container>
</section>
{/* <!-- End Amogh_Inamdar Section --> */}

{/* <!-- Start Our Team Section --> */}
<section className="about-bg gap-contact" >
<img src={top} alt="banner" className="topdesign" width={"20%"} />
<img src={right_bottom} alt="banner" id="Team" className="rightbottomdesign" width={"20%"} />
<Container className="gap aos-animate" data-aos="fade-up">
 <h1 className="our_team_heading" >Our Team</h1>
<Row>
    <Col xs={6} sm={6} md={6} lg={{span:2, offset:1}}>
        <div className="our_team_cont">
     <img src={Karan} alt="our team" className="ourteam_effect" width={"100%"} />
     <h5 className="ourteam-heading pt-4">Karan Kumar</h5>
     <p className="ourteam-sub-heading pb-3">Investor, Business Coach</p>
     </div>
        </Col>
        <Col xs={6} sm={6} md={6} lg={2}>
        <div className="our_team_cont">
     <img src={Ranganath} alt="our team" className="ourteam_effect" width={"100%"} />
     <h5 className="ourteam-heading pt-4">Ranganath Sadasiva</h5>
     <p className="ourteam-sub-heading pb-3">Chief Technology Mentor</p>
     </div>
        </Col>
        <Col xs={6} sm={6} md={6} lg={2}>
        <div className="our_team_cont">
     <img src={Vishwanath} alt="our team" className="ourteam_effect" width={"100%"} />
     <h5 className="ourteam-heading pt-4">Vishwanath Rumale</h5>
     <p className="ourteam-sub-heading pb-3">Technology Partner, IGS India</p>
     </div>
        </Col>
        <Col xs={6} sm={6} md={6} lg={2}>
        <div className="our_team_cont">
     <img src={Vishnukant} alt="our team" className="ourteam_effect" width={"100%"} />
     <h5 className="ourteam-heading pt-4">Dr. Vishnukant Chatpalli</h5>
     <p className="ourteam-sub-heading pb-3">Chief Advisor</p>
     </div>
        </Col>
        <Col xs={6} sm={6} md={6} lg={{span:2, offset:-1}}>
        <div className="our_team_cont">
          <img src={Guddushastri} alt="our team" className="ourteam_effect" width={"100%"} />
          <h5 className="ourteam-heading pt-4">G. M Inamdar</h5>
          <p className="ourteam-sub-heading pb-3">Managing Director</p>
          </div>
        </Col>
        <Col xs={6} sm={6} md={6} lg={{span:2, offset: 2}}>
        <div className="our_team_cont">
          <img src={Indumathi} alt="our team" className="ourteam_effect" width={"100%"} />
          <h5 className="ourteam-heading pt-4">Dr. Indumathi Rao</h5>
          <p className="ourteam-sub-heading pb-3">Social Impact Advisor</p>
          </div>
        </Col>
        <Col xs={6} sm={6} md={6} lg={2}>
        <div className="our_team_cont">
          <img src={Shailaja} alt="our team" className="ourteam_effect" width={"100%"} />
          <h5 className="ourteam-heading pt-4">Shailaja Inamdar</h5>
          <p className="ourteam-sub-heading pb-3">Product Guide</p>
          </div>
        </Col>
        <Col xs={6} sm={6} md={6} lg={2}>
        <div className="our_team_cont">
          <img src={Suma} alt="our team" className="ourteam_effect" width={"100%"} />
          <h5 className="ourteam-heading pt-4">Dr. H N Suma</h5>
          <p className="ourteam-sub-heading pb-3">Director, BIG Foundation</p>
          </div>
        </Col>
        <Col xs={6} sm={6} md={6} lg={2}>
        <div className="our_team_cont">
          <img src={Deepashree} alt="our team" className="ourteam_effect" width={"100%"} />
          <h5 className="ourteam-heading pt-4">Dr. Deepashree S R</h5>
          <p className="ourteam-sub-heading pb-3">Domain Expert Advisor</p>
          </div>
        </Col>
        
        
        
      </Row>
      </Container>
    </section>
    {/* <!-- End Our Team Section --> */}

    {/* <!-- ======= Start Contact Section ======= --> */}
    <section id="Contact" className="home-bg">
    <img src={bottom} alt="banner" className="bottomdesign" width={"20%"} />
    <img src={right_top} alt="banner" className="righttopdesign" width={"20%"} />
      <div className="gap">
        <div className="reach-contner aos-animate" data-aos="fade-up">
        <h1 className="reach-heading pb-3">Reach us at</h1>
        <p className="reach-para-mail"><a href="mailto:ai@ableproindia.com">
        <span><FiMail /></span>&nbsp;<span className="email-style">ai@ableproindia.com</span></a></p>
        <p className="reach-para"><FiPhone />&nbsp;<a href="tel:98441 32652">+91 98441 32652</a> | <a href="tel:97395 50801"> +91 97395 50801</a></p>
        </div>
      </div>
    </section>
{/* <!-- ======= Start Contact Section ======= --> */}

{/* <!-- ======= Start footer_logo Section ======= --> */}
<section className="about-bg">
<img src={top} alt="banner" className="lefttdesign" width={"20%"} />
<img src={right_bottom} alt="banner" className="rightbottomdesign" width={"20%"} />
<div className="gap">
<div className="reach-cont aos-animate" data-aos="fade-up" >
<img src={banner} alt="banner" className="logo_icon" width={"200"} />
<h1 className="footer-heading text-center pt-5">AblePro Solutions</h1>
<p className="footer-para text-center pt-3">Enable, Engage, Empower</p>
</div>
</div>
</section>
{/* <!-- ======= End footer_logo Section ======= --> */}

{/* <!-- ======= Start Footer Section ======= --> */}
<section className="home-bg">
  <p className="footer">ABLEPRO SOLUTIONS PVT. LTD. | ALL RIGHTS RESERVED | {new Date().getFullYear()}</p>
</section>
{/* <!-- ======= End Footer Section ======= --> */}

  </>
  )
}

export default Home
