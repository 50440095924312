import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './style.css';
import Logo_header from '../img/logo/Logo_header.png';
import {Link} from 'react-router-dom';
import {HashLink } from "react-router-hash-link";
import { FiHome, FiMail, FiInfo, FiUsers } from "react-icons/fi";

function BasicExample() {
  const [open , setOpen] = useState(false)

  return (
    <>
  <label onClick={()=> setOpen(true)} id="close-button" htmlFor="nav" className="nav-btn"></label>
    <Navbar expand="lg" className="bg-body-tertiary" expanded={open}>
      <Container fluid>
        <Link to="./"><img src={Logo_header} alt="logo" className="header-logo" width={"80%"}  />
        </Link>

        <Navbar.Toggle id="close-button"  aria-controls="basic-navbar-nav" onClick={() => setOpen(!open)} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <HashLink id="close-button" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}  spy="true" smooth={true} duration={500} onClick={()=> setOpen(false)} className='nav-item-style'  to="/#Home"> 
            <FiHome  />  Home </HashLink>
           <HashLink id="close-button" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })} spy="true" smooth={true} duration={500} onClick={()=> setOpen(false)}  className='nav-item-style'  to="/#About"><FiInfo /> About </HashLink>
           <HashLink id="close-button" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })} spy="true" smooth={true} duration={500} onClick={()=> setOpen(false)}  className='nav-item-style'  to="/#Team"><FiUsers /> Team </HashLink>
           {/* <HashLink id="close-button" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })} spy="true" smooth={true} duration={500} onClick={()=> setOpen(false)}  className='nav-item-style'  to="/products"><FiUsers /> Product </HashLink> */}
           <HashLink id="close-button" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}  spy="true" smooth={true} duration={500} onClick={()=> setOpen(false)}  className='nav-item-style'  to="/#Contact"><FiMail /> Contact </HashLink>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default BasicExample;